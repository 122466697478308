import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { Section, GreenLink } from "../style/theme";

function AboutSite() {
  return (
    <Section>
      <h3>ما هو الفِهرِست</h3>
      <p>الفِهرِست موقع يُعنى بالتدوين الشخصي، على وجهين:</p>
      <ul>
        <li>
          الأوّل: في قاعدة بيانات تضم مئات المدونات العربيّة. نعتمد على هذه
          القاعدة في جلب أحدث التدوينات المنشورة في الفضاء العربي وإتاحتها
          للقرّاء في صفحة واحدة.
        </li>
        <li
          css={{
            textDecoration: "line-through",
          }}
        >
          الآخر: التشبيك فيما بينها من خلال{" "}
          <GreenLink
            href="https://forum.elfehrest.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            مجتمع الفهرست
          </GreenLink>
          ، والذي يستضيف المدونين أنفسهم، ويفتح باب التواصل، تبادل الأفكار
          والخبرات، التدوين في حملات وغير ذلك من أشكال التعاون.
        </li>
      </ul>
    </Section>
  );
}

function WhySite() {
  return (
    <Section>
      <h3>لماذا الفِهرِست</h3>
      <p>
        ببساطة لأننا لازلنا نؤمن بالتدوين المكتوب كواحدٍ من أهم أشكال التواصل،
        وإثراء الحياة الشخصية والعامة.
      </p>
      <p>
        يوميًا نحن نتعامل مع دفق كبير من الصور، الصور المتحركة، مقاطع الڤيديو،
        ومقتطفات خفيفة من المنشورات المكتوبة على وسائل التواصل الاجتماعي. ولا
        مشكلة في هذا كله، لكننا نتفقد كذلك للنصوص المطوّلة، التجارب الشخصية
        الحقيقية، واليوميات الواقعية، في زحمة ما تُقّرره عنّا خوارزميات المواقع
        الاجتماعية وإعلاناتها المروّجة.
      </p>
    </Section>
  );
}

function WhoAmI() {
  return (
    <Section>
      <h3>حول الفِهرِست</h3>
      <p>
        اسمي
        <GreenLink href="https://blog.tareef.me/"> طريف مندو </GreenLink>
        مدوّن منذ عام 2008 وأعمل كمطوّر ويب، مُتخصّص في بناء مواقع وخدمات{" "}
        <strong>سريعة كالبرق،</strong> مستعملا تقنيات الويب الحديثة.
      </p>
      <p>
        قمتُ ببناء الفهرست لخدمة المحتوى العربي على الإنترنت، وتشجيع حركة
        <strong> التدوين الشخصي </strong>.
      </p>
      <p>
        الواجهة الأماميّة بُنيت باستخدام إطار العمل
        <GreenLink href="https://www.gatsbyjs.org/"> غاتسبي</GreenLink> وهي
        عبارة عن ملفات هتمل ثابتة. الواجهة الخلفية بُنيت باستعمال
        <GreenLink href="http://nodejs.org/"> نود.جي.إس</GreenLink>. وكذلك واجهة
        برمجة التطبيقات التي تجلب المزيد من المحتوى عند الطلب.
      </p>

      <p>
        يمكن التواصل معي عبر{" "}
        <GreenLink href="mailto:mando@tareef.me">بريدي الشخصي</GreenLink>
      </p>
      <p>
        جميع المراسلات المتعلقة بالفِهرِست يُرجى إرسالها{" "}
        <GreenLink href="mailto:admin@elfehrest.com">
          عبر البريد الرسمي للموقع
        </GreenLink>
      </p>
    </Section>
  );
}

function ReadMore() {
  return (
    <Section>
      <h3>إقرأ المزيد</h3>
      <ul>
        <li>
          <GreenLink as={Link} to="/help/faq">
            الأسئلة الأكثر شيوعًا
          </GreenLink>
        </li>
        <li>
          <GreenLink as={Link} to="/about/write-with-us">
            اكتب معنا في مدوّنة الفهرست
          </GreenLink>
        </li>
      </ul>
    </Section>
  );
}

function AboutPage() {
  return (
    <Layout>
      <SEO title="حول" />
      <AboutSite />
      <WhySite />
      <WhoAmI />
      <ReadMore />
    </Layout>
  );
}

export default AboutPage;

export const pageQuery = graphql`
  query About {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
      filter: { frontmatter: { isBlog: { eq: false } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            isBlog
          }
        }
      }
    }
  }
`;
